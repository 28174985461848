<template>
    <div class="login-wrap">
        <div class="ms-login">
            <div class="ms-title">龟鹤学堂后台管理系统</div>
            <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                <el-form-item prop="username">
                    <el-input v-model="param.user_name" placeholder="username">
                        <template #prepend>
                            <el-button icon="el-icon-user"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input
                        type="password"
                        placeholder="password"
                        v-model="param.password"
                        @keyup.enter="submitForm()"
                    >
                        <template #prepend>
                            <el-button icon="el-icon-lock"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <div class="login-btn">
                    <el-button type="primary" @click="submitForm()">登录</el-button>
                </div>
            </el-form>
        </div>
        <div class="copy_write">©2021-2023 龟鹤学堂教育科技产业集团有限公司 版权所有 侵权必究 <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2021020048号-1</a> </div>
    </div>
</template>

<script>
import request from '@/utils/request'
import store from '@/store/index.js'
export default {
    data() {
        return {
            param: {
                user_name: "",
                password: ""
            },
            rules: {
                user_name: [
                    { required: true, message: "请输入用户名", trigger: "blur" }
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" }
                ]
            }
        };
    },
    created() {
        this.$store.commit("clearTags");
    },
    methods: {
        submitForm() {
            this.$refs.login.validate(valid => {
                if (valid) {
                    this.login()
                    // this.$message.success("登录成功");
                    // localStorage.setItem("ms_username", this.param.username);
                    // this.$router.push("/");
                } else {
                    this.$message.error("请输入账号和密码");
                    return false;
                }
            });
        },
        login() {
            request({
                    url: '/admin/login',
                    method: 'post',
                    data:{...this.param}
                }).then(res => {
                    if(res.code==200){
                        this.$message.success("登录成功");
                        localStorage.setItem('_token',res.data.token_type+' '+res.data.access_token)
                        this.getuser(res.data.token_type+' '+res.data.access_token)
                        // if(this.checked){
                        //     localStorage.setItem('_user_name',this.param.username)
                        // }else{
                        //     localStorage.removeItem('_user_name')
                        // }
                    }else{
                        this.$message.error(res.msg)
                    }
                }).catch(err => {
                    console.log(err)
            });
        },
        getMenuList() {
            request({
                    url: 'admin/index/menuList',
                    method: 'get',
                }).then(({code,data}) => {
                    if(code==200){
                        let items = [];
                        for(let i in data) {
                            let item = {
                                icon : data[i].icon,
                                index : data[i].url||data[i].id,
                                title : data[i].name
                            }
                            
                            if(data[i].menu_lists.length>0) {
                                let subs = []
                                for (let o in data[i].menu_lists) {
                                    let child ={
                                        // icon : data[i].menu_lists[o].icon,
                                        index : data[i].menu_lists[o].url||data[i].menu_lists[o].id,
                                        title : data[i].menu_lists[o].name
                                    }
                                    subs.push(child)
                                }
                                item.subs = subs
                            } 
                            items.push(item)
                        }
                        // this.items = items.concat(this.items)
                        // console.log(items)
                        store.commit('setMenuList', items)
                    }else{
                        //this.$message.error(res.msg)
                    }
                }).catch(err => {
                    console.log(err)
            });
        },
        getuser(token) {
            request({
				url: '/admin/index/getUserInfo',
                method: 'get',
                headers: {
                    "Authorization": token
                }
			}).then(res => {
                if(res.code==200){
                    this.$message.success('登录成功');
                    localStorage.setItem('ms_username', res.data.user_name);
                    localStorage.setItem('ms_user', JSON.stringify(res.data));
                    this.$router.push('/');
					this.getMenuList()
                }else{
                    //this.$message.error(res.msg)
                }
			}).catch(err => {
                console.log(err)
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../assets/img/login-bg.jpg);
    background-size: 100%;
}
.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    border-bottom: 1px solid #ddd;
}
.ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    margin: -190px 0 0 -175px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
    overflow: hidden;
}
.ms-content {
    padding: 30px 30px;
}
.login-btn {
    text-align: center;
}
.login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
}
.login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
}
.copy_write {
    width: 100vw;
    text-align: center;
    position: fixed;
    bottom: 30px;
    left: 0;
    color: #333;
    a {
        color: #333;
        &:hover {
            color: #409eff;
        }
    }
}
</style>